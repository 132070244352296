@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .logo-yellow {
    @apply bg-[rgb(255,210,3)];
  }
  .footer-header {
    @apply font-bold font-serif text-xl border-b-slate-700 border-b-[1.6px] mb-4 pb-4;
  }
  .footer-text {
    @apply text-gray-400;
  }
  .footer-icons {
    @apply text-[#FED000];
  }
  .padding-border {
    @apply pb-3 border-b-slate-800 border-b-[1.5px];
  }
  .footer-services:hover {
    @apply text-[#FED000] duration-500 translate-x-2;
  }
  .nav-hover:hover {
    @apply bg-[#FED000] text-white;
  }
  .button {
    @apply bg-[#FED000] text-[#233542] hover:bg-inherit hover:text-[#FED000] border-[#FED000] border-2 px-3 py-2 rounded-md;
  }

  .text-font {
    @apply  font-serif;
  }

  .heading-font {
    @apply not-italic font-serif;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animated-button {
  animation: moveUpDown 2s infinite alternate ease-in-out;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Adjust this value for desired animation */
  }
}

.curve-underline {
  position: relative;
  text-decoration: none;
}

.curve-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  border-top: solid 2px #ea0b00;
  left: 0;
  bottom: -10px;
  border-radius: 50%;
  height: 8px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev::after {
  content: "next";
  color: gray;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: gray;
  display: none;
  line-height: 100;
}

.swiper-pagination-bullet-active {
  background-color: gray;
}

.animated-button {
  animation: moveUpDown 2s infinite alternate ease-in-out;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Adjust this value for desired animation */
  }
}


